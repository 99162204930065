import React from "react";
import classes from "./Button.module.scss";

const Button = ({children, login, white}) => {
  return (
    <>
      {login ?
        <a href="https://user.vendigital.icu/login" className={`${classes.button} ${white && classes.white}`}>
          <span>{children}</span>
        </a>
        :
        <a href="https://user.vendigital.icu/signup" className={`${classes.button} ${white && classes.white}`}>
          <span>{children}</span>
        </a>
      }
    </>
  );
};

export default Button;
